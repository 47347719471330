import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../Css/TooteDetailid.css';
import Footer from '../Components/Footer';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

const extractYouTubeID = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))?v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : null;
};

const getYouTubeEmbedUrl = (url) => {
    const videoId = extractYouTubeID(url);
    return `https://www.youtube.com/embed/${videoId}?rel=0`;
};

const TooteDetailid = () => {
    const { t, i18n } = useTranslation(); // Kasuta i18n keele jaoks
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [mainImage, setMainImage] = useState('');
    const [mainImageIsVideo, setMainImageIsVideo] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        import(`../Data/tooted${i18n.language.toLowerCase()}.json`)
            .then(tooted => {
                const allProducts = tooted.default.flatMap(category => {
                    return category.tooted.map(toode => ({
                        ...toode,
                        categoryName: category.nimi
                    }));
                });
                const foundProduct = allProducts.find(toode => toode.id.toString() === productId);
                if (foundProduct) {
                    setProduct(foundProduct);
                    if (foundProduct.videoUrl) {
                        setMainImage(getYouTubeEmbedUrl(foundProduct.videoUrl));
                        setMainImageIsVideo(true);
                    } else {
                        setMainImage(foundProduct.pilt || '');
                        setMainImageIsVideo(false);
                    }
                }
            });
    }, [productId, i18n.language]);

    const handleThumbnailClick = (imageUrl, isVideo = false) => {
        setMainImage(isVideo ? getYouTubeEmbedUrl(imageUrl) : imageUrl);
        setMainImageIsVideo(isVideo);
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    if (!product) {
        return (
            <div className="loading-message flex flex-col items-center justify-center min-h-screen">
                <div className="animation-container spinner"></div>
                <p>{t('productDetails.loading')}</p>
                <button onClick={() => window.location.reload()} className="refresh-button">{t('productDetails.refresh')}</button>
                <Link to={`/${i18n.language}/kontakt`} className="refresh-button">{t('productDetails.contact')}</Link>
            </div>
        );
    }

    return (
        <>         
            <div className="container mx-auto py-12">
                <div className="bg-white p-6 rounded-lg shadow-md tooteInfo">
                    <h2 className="text-2xl font-bold mb-4">{product.nimi}</h2>
                    <Link to={`/${i18n.language}/tooted/${encodeURIComponent(product.categoryName)}`} className="button button-primary">{t('productDetails.tagasi')}</Link>
                    <div className="imageGallery flex justify-center gap-4 mt-4">
                        {mainImageIsVideo ? (
                            <div className="video-responsive">
                                <iframe
                                    src={mainImage}
                                    title={product.nimi ? `${product.nimi} video esitlus` : 'Video esitlus'} // Kontrollime, et iframe'il oleks pealkiri
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        ) : (
                            mainImage && ( // Kuvame ainult siis, kui mainImage on saadaval
                                <img
                                    src={`${process.env.PUBLIC_URL}/${mainImage}`}
                                    alt={product.nimi ? `${product.nimi} peamine pilt – mobiilne spiromeeter Bluetooth ühendusega` : ''} // Alt-tekst või tühi string
                                    className={`mainImage`}
                                    onClick={openModal}
                                />
                            )
                        )}
                        <div className="thumbnailContainer flex flex-col gap-2 overflow-y-auto max-h-64">
                            {product.videoUrl && (
                                <img
                                    src={`${process.env.PUBLIC_URL}/TootePildid/videothumb.webp`}
                                    alt={product.nimi ? `${product.nimi} video eelvaade` : ''} // Alt-tekst või tühi string
                                    className="thumbnail" 
                                    onClick={() => handleThumbnailClick(product.videoUrl, true)}
                                />
                            )}
                            {[product.pilt, ...(product.pildid || [])].map((imgUrl, index) => (
                                imgUrl && ( // Kuvame ainult, kui imgUrl on saadaval
                                    <img
                                        key={index}
                                        src={`${process.env.PUBLIC_URL}/${imgUrl}`} 
                                        alt={product.nimi ? `${product.nimi} ${index + 1} – tootevaade ${index + 1}` : ''} // Alt-tekst või tühi string
                                        className="thumbnail" 
                                        onClick={() => handleThumbnailClick(imgUrl, false)}
                                    />
                                )
                            ))}
                        </div>
                    </div>

                    <section className='kirjeldus mt-6'>
                        <h3 className="text-xl font-semibold">{t('productDetails.overview')}</h3>
                        <p className="mt-2">{product.kirjeldus}</p>
                        <ul className="list-disc list-inside mt-2">
                            {product.omadused.map((omadus, index) => (
                                <li key={index}>{omadus}</li>
                            ))}
                        </ul>
                        <p className="mt-2">{product.kirjeldus2}</p>

                        {product.datasheets && product.datasheets.length > 0 && (
                            <div className="datasheet-section mt-4">
                                <h3 className="text-xl font-semibold">{t('productDetails.data')}</h3>
                                <ul>
                                    {product.datasheets.map((datasheet, index) => (
                                        <li key={index}>
                                            <a href={`${process.env.PUBLIC_URL}/${datasheet.url}`} target="_blank" rel="noopener noreferrer">
                                                {datasheet.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                    </section>
                    <Link to={`/${i18n.language}/kontakt`} className="button">{t('productDetails.button')}</Link>
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Image Gallery"
                className="modal"
                overlayClassName="modal-overlay"
            >
                <img
                    src={`${process.env.PUBLIC_URL}/${mainImage}`}
                    alt={product.nimi}
                    className="modal-image"
                />
                <button onClick={closeModal} className="close-modal">{t('productDetails.close')}</button>
            </Modal>

            <Footer />
        </>
    );
};

export default TooteDetailid;
